import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, unref as _unref, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "layer" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 3,
  class: "desc"
}
const _hoisted_5 = { class: "opera" }

import { ref, reactive } from 'vue'
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'item',
  props: {
  item: {
    type: Object,
    default() {
      return {}
    }
  },
},
  emits: ['onShow', 'onDel', 'onSelect'],
  setup(__props, { expose: __expose, emit: __emit }) {

const { t, locale } = useI18n();
const props = __props
const emits = __emit

__expose({})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (__props.item.type === 'text')
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "text",
            onClick: _cache[0] || (_cache[0] = () => emits('onSelect', __props.item))
          }, _toDisplayString(__props.item.value), 1))
        : (__props.item.type === 'image')
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: "image",
              onClick: _cache[1] || (_cache[1] = () => emits('onSelect', __props.item))
            }, [
              _createElementVNode("img", {
                src: __props.item.value,
                alt: ""
              }, null, 8, _hoisted_3)
            ]))
          : (__props.item.type === 'color')
            ? (_openBlock(), _createElementBlock("div", {
                key: 2,
                class: "color",
                onClick: _cache[2] || (_cache[2] = () => emits('onSelect', __props.item)),
                style: _normalizeStyle({ backgroundColor: '#' + __props.item.value })
              }, null, 4))
            : _createCommentVNode("", true),
      (__props.item.bgType)
        ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_unref(t)('layer_bg')), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", {
        class: "opera-item",
        onClick: _cache[3] || (_cache[3] = () => emits('onLock', __props.item))
      }, [
        _createElementVNode("i", {
          class: _normalizeClass(["iconfont icon-suoding", [__props.item.lock ? 'icon-suoding' : 'icon-jiesuo']])
        }, null, 2)
      ]),
      _createElementVNode("div", {
        class: "opera-item",
        onClick: _cache[4] || (_cache[4] = () => emits('onShow', __props.item))
      }, [
        _createElementVNode("i", {
          class: _normalizeClass(["iconfont", [__props.item.show ? 'icon-xianshiyincang-' : 'icon-xianshiyincang-1']])
        }, null, 2)
      ]),
      _createElementVNode("div", {
        class: "opera-item",
        onClick: _cache[5] || (_cache[5] = () => emits('onDel', __props.item))
      }, _cache[6] || (_cache[6] = [
        _createElementVNode("i", { class: "iconfont icon-shanchu" }, null, -1)
      ]))
    ])
  ]))
}
}

})