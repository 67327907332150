export const loadImage = (base64: string) => {
  return new Promise((resolve, reject) => {
    const image = new Image()
    image.onload = () => {
      resolve({
        width: image.width,
        height: image.height
      })
    }
    image.onerror = (err) => {
      console.log(base64, '--')
      console.log('error', err)
      reject(err)
    }
    image.src = base64
  })
}

export const wmHookDemo = {"orderType":1,"orderSource":0,"orderNo":1512007883991481,"discountTypes":[],"productId":145,"deliveryType":1,"productInstanceId":12799718114,"orderStatus":3,"saleAttributionVid":6016367139114,"channelType":1,"updateTime":1730789463000,"parentOrderNo":1512007882991481,"saleChannelType":10001,"paymentType":1,"tcode":"weimob","vid":6016367139114,"wid":10951287614,"createTime":1730789462839,"merchantId":2000314234114,"saleAttributionVidType":2,"bosId":4021881002114,"saleAttributionVidSource":1,"bizSourceType":1,"paymentStatus":2}
