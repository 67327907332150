import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }

import { ref, reactive } from 'vue'
import { ITabType } from '@/types'
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'baseTab',
  props: {
  
},
  emits: ['onTabClick'],
  setup(__props, { expose: __expose, emit: __emit }) {

const { t, locale } = useI18n();
const props = __props
const emits = __emit

const onItemClick = (type: ITabType) => {
  emits('onTabClick', type)
}

__expose({})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "row-item",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (onItemClick('modal')))
    }, [
      _cache[8] || (_cache[8] = _createElementVNode("i", { class: "iconfont icon-kuanshiguanli" }, null, -1)),
      _createElementVNode("p", null, _toDisplayString(_unref(t)('product')), 1)
    ]),
    _createElementVNode("div", {
      class: "row-item",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (onItemClick('designArea')))
    }, [
      _cache[9] || (_cache[9] = _createElementVNode("i", { class: "iconfont icon-yishu" }, null, -1)),
      _createElementVNode("p", null, _toDisplayString(_unref(t)('display_method')), 1)
    ]),
    _createElementVNode("div", {
      class: "row-item",
      onClick: _cache[2] || (_cache[2] = ($event: any) => (onItemClick('image')))
    }, [
      _cache[10] || (_cache[10] = _createElementVNode("i", { class: "iconfont icon-tupian_huaban" }, null, -1)),
      _createElementVNode("p", null, _toDisplayString(_unref(t)('image')), 1)
    ]),
    _createElementVNode("div", {
      class: "row-item",
      onClick: _cache[3] || (_cache[3] = ($event: any) => (onItemClick('text')))
    }, [
      _cache[11] || (_cache[11] = _createElementVNode("i", { class: "iconfont icon-a-8ziti" }, null, -1)),
      _createElementVNode("p", null, _toDisplayString(_unref(t)('text')), 1)
    ]),
    _createElementVNode("div", {
      class: "row-item",
      onClick: _cache[4] || (_cache[4] = ($event: any) => (onItemClick('stickers')))
    }, [
      _cache[12] || (_cache[12] = _createElementVNode("i", { class: "iconfont icon-wenjianjia" }, null, -1)),
      _createElementVNode("p", null, _toDisplayString(_unref(t)('stickers')), 1)
    ]),
    _createElementVNode("div", {
      class: "row-item",
      onClick: _cache[5] || (_cache[5] = ($event: any) => (onItemClick('bg')))
    }, [
      _cache[13] || (_cache[13] = _createElementVNode("i", { class: "iconfont icon-beijing" }, null, -1)),
      _createElementVNode("p", null, _toDisplayString(_unref(t)('background')), 1)
    ]),
    _createElementVNode("div", {
      class: "row-item",
      onClick: _cache[6] || (_cache[6] = ($event: any) => (onItemClick('design')))
    }, [
      _cache[14] || (_cache[14] = _createElementVNode("i", { class: "iconfont icon-xianxing-_caidan1" }, null, -1)),
      _createElementVNode("p", null, _toDisplayString(_unref(t)('design')), 1)
    ]),
    _createElementVNode("div", {
      class: "row-item",
      onClick: _cache[7] || (_cache[7] = ($event: any) => (onItemClick('layer')))
    }, [
      _cache[15] || (_cache[15] = _createElementVNode("i", { class: "iconfont icon-a-2sucai" }, null, -1)),
      _createElementVNode("p", null, _toDisplayString(_unref(t)('layer')), 1)
    ])
  ]))
}
}

})