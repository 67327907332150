import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "design-area" }
const _hoisted_2 = { class: "flex flex-wrap" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "flex image items-align justify-center" }
const _hoisted_5 = ["src"]

import { ref, reactive } from 'vue'
import type { ModalItem, ModalList } from './types'
import Pixi from '../core'
import { getIntegrateUrl } from '../utils'


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
  app: {
    type: Object,
    default() {
      return {}
    }
  },
  list: {
    type: Array,
    default() {
      return []
    }
  },
},
  emits: ['setLoading'],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props
const emits = __emit

const onItemClick = async (item: string) => {
  emits('setLoading', true, '正在切换展示方式')
  props.app?.setDesginArea(item, () => {
    emits('setLoading', false)
  })
}

__expose({})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.list, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "item flex flex-col",
          onClick: ($event: any) => (onItemClick(item))
        }, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("img", {
              src: _unref(getIntegrateUrl)(item, 'tplv-z8ialgywaa-w-size:300'),
              alt: ""
            }, null, 8, _hoisted_5)
          ])
        ], 8, _hoisted_3))
      }), 128))
    ])
  ]))
}
}

})