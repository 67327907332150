import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "modal" }

import { ref, reactive } from 'vue'
import type { ModalItem, ModalList } from './types'
import Pixi from '../core'


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
  app: {
    type: Object,
    default() {
      return {}
    }
  }
},
  emits: [],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props
const emits = __emit

const modal = reactive<ModalList>({
  list: [
    // { thumbnail: DemoImage, showConfigImage: DemoShowConfigImage, name: 'iPhone 12 Pro Max', price: 19.98 },
    // { thumbnail: DemoImage2, showConfigImage: DemoShowConfigImage, name: 'iPhone 14 Pro Max', price: 39.98 },
  ],
  total: 0,
})
const onItemClick = (item: ModalItem) => {
  props.app?.addShellImage(item.thumbnail, item.showConfigImage)
}

__expose({})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("div", { class: "flex flex-wrap gap-2" }, [
      _createElementVNode("p", { class: "tip" }, "暂未开放切换产品功能")
    ], -1)
  ])))
}
}

})