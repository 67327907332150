import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "row" }

import { ref, reactive } from 'vue'
import { ISpriteIndexType, ITabType } from '@/types'
import { Sprite } from 'pixi.js'
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
  app: {
    type: Object
  },
  spriteType: {
    type: String
  },
},
  emits: ['onTabClick', 'onClosePanel', 'setCutImageUrl'],
  setup(__props, { expose: __expose, emit: __emit }) {

const { t, locale } = useI18n();
const props = __props
const emits = __emit

const onItemClick = (type: ITabType) => {
  emits('onTabClick', type)
}
const onCutImageClick = (type: ITabType) => {
  const sprite: Sprite | null = props.app?.target.sprite
  sprite && emits('setCutImageUrl', sprite.texture.baseTexture.cacheId, (base64: string) => {
    props.app?.setSpriteTexture(sprite, base64)
  })
}
const onMirror = (type: 'x' | 'y') => {
  const { sprite } = props.app?.target
  props.app?.mirror(sprite, type)
}
const onSetIndex = (type: ISpriteIndexType) => {
  const { sprite } = props.app?.target
  props.app?.setIndex(sprite, type)
}

const onCopy = () => {
  const { sprite } = props.app?.target
  props.app?.copy(sprite)
}

const onCartoon = () => {
  const { sprite } = props.app?.target
  props.app?.setSpriteCartoon(sprite)
}

const onCutout = () => {
  const { sprite } = props.app?.target
  props.app?.setSpriteCutout(sprite, 'people')
}

const onCutoutStuff = () => {
  const { sprite } = props.app?.target
  props.app?.setSpriteCutout(sprite, 'stuff')
}

const onBack = () => {
  props.app?.hideOperateIconContainer()
  props.app?.resetEventTarget()
  props.app?.option?.onCancelSprite()
  emits('onClosePanel')
}

__expose({})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "row-item",
      onClick: onBack
    }, [
      _cache[8] || (_cache[8] = _createElementVNode("i", { class: "iconfont icon-shangyibu" }, null, -1)),
      _createElementVNode("p", null, _toDisplayString(_unref(t)('back')), 1)
    ]),
    (__props.spriteType === 'text')
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "row-item",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (onItemClick('text')))
        }, [
          _cache[9] || (_cache[9] = _createElementVNode("i", { class: "iconfont icon-bianji" }, null, -1)),
          _createElementVNode("p", null, _toDisplayString(_unref(t)('font_oper')), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: "row-item",
      onClick: onCopy
    }, [
      _cache[10] || (_cache[10] = _createElementVNode("i", { class: "iconfont icon-a-15fuzhi" }, null, -1)),
      _createElementVNode("p", null, _toDisplayString(_unref(t)('copy')), 1)
    ]),
    (__props.spriteType !== 'text')
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "row-item",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (onCutImageClick('cutImage')))
        }, [
          _cache[11] || (_cache[11] = _createElementVNode("i", { class: "iconfont icon-a-maohuopi1" }, null, -1)),
          _createElementVNode("p", null, _toDisplayString(_unref(t)('crop')), 1)
        ]))
      : _createCommentVNode("", true),
    (__props.spriteType !== 'text')
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: "row-item",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (onItemClick('colorFill')))
        }, [
          _cache[12] || (_cache[12] = _createElementVNode("i", { class: "iconfont icon-a-13tianchong" }, null, -1)),
          _createElementVNode("p", null, _toDisplayString(_unref(t)('fill_color')), 1)
        ]))
      : _createCommentVNode("", true),
    (__props.spriteType !== 'text')
      ? (_openBlock(), _createElementBlock("div", {
          key: 3,
          class: "row-item",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (onItemClick('imageFill')))
        }, [
          _cache[13] || (_cache[13] = _createElementVNode("i", { class: "iconfont icon-a-14zhaopiantianchong" }, null, -1)),
          _createElementVNode("p", null, _toDisplayString(_unref(t)('fill_image')), 1)
        ]))
      : _createCommentVNode("", true),
    (__props.spriteType !== 'text')
      ? (_openBlock(), _createElementBlock("div", {
          key: 4,
          class: "row-item",
          onClick: onCartoon
        }, [
          _cache[14] || (_cache[14] = _createElementVNode("i", { class: "iconfont icon-a-12manhuafeng" }, null, -1)),
          _createElementVNode("p", null, _toDisplayString(_unref(t)('style_comics')), 1)
        ]))
      : _createCommentVNode("", true),
    (__props.spriteType !== 'text')
      ? (_openBlock(), _createElementBlock("div", {
          key: 5,
          class: "row-item",
          onClick: onCutout
        }, [
          _cache[15] || (_cache[15] = _createElementVNode("i", { class: "iconfont icon-a-1koutu" }, null, -1)),
          _createElementVNode("p", null, _toDisplayString(_unref(t)('remove_bg')), 1)
        ]))
      : _createCommentVNode("", true),
    (__props.spriteType !== 'text')
      ? (_openBlock(), _createElementBlock("div", {
          key: 6,
          class: "row-item",
          onClick: onCutoutStuff
        }, [
          _cache[16] || (_cache[16] = _createElementVNode("i", { class: "iconfont icon-a-1koutu" }, null, -1)),
          _createElementVNode("p", null, _toDisplayString(_unref(t)('body_bg')), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: "row-item",
      onClick: _cache[4] || (_cache[4] = ($event: any) => (onSetIndex('top')))
    }, [
      _cache[17] || (_cache[17] = _createElementVNode("i", { class: "iconfont icon-a-10qianmian" }, null, -1)),
      _createElementVNode("p", null, _toDisplayString(_unref(t)('pin-top')), 1)
    ]),
    _createElementVNode("div", {
      class: "row-item",
      onClick: _cache[5] || (_cache[5] = ($event: any) => (onSetIndex('bottom')))
    }, [
      _cache[18] || (_cache[18] = _createElementVNode("i", { class: "iconfont icon-a-11houmian" }, null, -1)),
      _createElementVNode("p", null, _toDisplayString(_unref(t)('pin-bottom')), 1)
    ]),
    _createElementVNode("div", {
      class: "row-item",
      onClick: _cache[6] || (_cache[6] = ($event: any) => (onMirror('x')))
    }, [
      _cache[19] || (_cache[19] = _createElementVNode("i", { class: "iconfont icon-a-16zuoyoujingxiang" }, null, -1)),
      _createElementVNode("p", null, _toDisplayString(_unref(t)('mirror-hori')), 1)
    ]),
    _createElementVNode("div", {
      class: "row-item",
      onClick: _cache[7] || (_cache[7] = ($event: any) => (onMirror('y')))
    }, [
      _cache[20] || (_cache[20] = _createElementVNode("i", { class: "iconfont icon-a-17shangxiajingxiang" }, null, -1)),
      _createElementVNode("p", null, _toDisplayString(_unref(t)('mirror-vert')), 1)
    ])
  ]))
}
}

})