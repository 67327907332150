import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "image-fill" }
const _hoisted_2 = { class: "select-image" }
const _hoisted_3 = ["src"]

import { ref, reactive } from 'vue'
import { Uploader, Notify, Loading, } from 'vant'
import { addFile } from '@/components/update'
import { getIntegrateUrl } from '../utils'

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
  app: {
    type: Object,
    default() {
      return {}
    }
  }
},
  emits: [],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props
const emits = __emit

const imageUrl = ref('')
const showNotifyLoading = ref(false)
const afterRead = async (file: any) => {
  showNotifyLoading.value = true
  console.log(file, '---')
  const urlValue = await addFile(file.content as string)
  let url = getIntegrateUrl(urlValue)
  const { sprite } = props.app?.target
  sprite && await props.app?.fillImageSpite(sprite, url)
  imageUrl.value = url
  showNotifyLoading.value = false
}

const Upload = ref()
const onUpload = () => {
  console.log(Upload.value, '-Upload.value')
  Upload.value?.chooseFile()
}
const onReset = () => {
  const { sprite } = props.app?.target
  sprite && props.app?.resetImageFill(sprite)
  imageUrl.value = ''
}

__expose({
  setFillImage: (url: string) => {
    imageUrl.value = url
  }
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (imageUrl.value)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", { class: "opera" }, [
            _createElementVNode("button", {
              class: "primary",
              onClick: onUpload
            }, "重新选择"),
            _createElementVNode("button", { onClick: onReset }, "清空")
          ]),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("img", {
              src: imageUrl.value,
              alt: ""
            }, null, 8, _hoisted_3)
          ])
        ], 64))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _cache[2] || (_cache[2] = _createElementVNode("p", { class: "default-text" }, "选择照片", -1)),
          _createElementVNode("div", {
            class: "upload",
            onClick: onUpload
          }, _cache[1] || (_cache[1] = [
            _createElementVNode("i", { class: "iconfont icon-plus" }, null, -1)
          ]))
        ], 64)),
    _withDirectives(_createVNode(_unref(Uploader), {
      ref_key: "Upload",
      ref: Upload,
      "after-read": afterRead
    }, null, 512), [
      [_vShow, false]
    ]),
    _createVNode(_unref(Notify), {
      show: showNotifyLoading.value,
      "onUpdate:show": _cache[0] || (_cache[0] = ($event: any) => ((showNotifyLoading).value = $event)),
      type: "success"
    }, {
      default: _withCtx(() => [
        _createVNode(_unref(Loading), {
          color: "#ffffff",
          size: "24"
        }),
        _cache[3] || (_cache[3] = _createElementVNode("span", { style: {"margin-left":"6px"} }, "图片上传中...", -1))
      ]),
      _: 1
    }, 8, ["show"])
  ]))
}
}

})