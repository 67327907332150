import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

import { ref, reactive } from 'vue'
import { getIntegrateUrl } from '../utils'

export default /*@__PURE__*/_defineComponent({
  __name: 'item',
  props: {
  item: {
    type: Object,
    default() {
      return {}
    }
  }
},
  emits: ['onLoad'],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props
const emits = __emit
__expose({})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "design",
    onClick: _cache[0] || (_cache[0] = () => emits('onLoad', __props.item))
  }, [
    _createElementVNode("img", {
      src: _unref(getIntegrateUrl)(__props.item.effect_image, 'tplv-z8ialgywaa-w-size:250'),
      alt: ""
    }, null, 8, _hoisted_1)
  ]))
}
}

})