import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "color-fill" }

import { ref, reactive } from 'vue'
import ColorPick from '@/components/ColorPick/index.vue'
import { prop } from 'vue-class-component'


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
  app: {
    type: Object,
    default() {
      return {}
    }
  }
},
  emits: [],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props
const emits = __emit
const handleColorChange = (color: string) => {
  // console.log(color, '--color')
  const { sprite } = props.app?.target
  sprite && props.app?.fillColorSprite(sprite, color)
}
__expose({})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(ColorPick, { onChange: handleColorChange })
  ]))
}
}

})