<template>
  <div class="color-pick">
    <div class="inner">
      <div class="scroll">
        <!-- 透明 -->
        <span v-if="hasOpacity" class="color-space color-item" @click="setNowColor('space')">
          <img src="./image/space-icon.png" :class="{'active': currColor === 'space'}" alt="" style="width: 100%;position: absolute;">
          <img class="" v-if="currColor === 'space'" src="./image/tub-7.png" alt="" style="width: 100%;position: absolute;">
        </span>
        <!-- 常用颜色 -->
        <span
          v-for="(color, index) in colorEnum"
          :key="index"
          class="color-item"
          :class="{'active': currColor === color}"
          :style="{'background-color': color}"
          @click="setNowColor(color)">
        </span>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      value: {
        type: String,
        default: ''
      },
      hasOpacity: {
        type: Boolean,
        default: true
      },
    },
    data() {
      return {
        currColor: 'space',
        colorEnum: Object.freeze([
          '#2e5799', '#233555', '#009199', '#2a6e3a', '#00451c', '#fde900', '#fd810e', '#f50808', '#010101', '#fffffe'
        ]),
      }
    },
    created() {
      this.currColor = this.value
    },
    watch: {
      value(val) {
        this.currColor = val
      },
    },
    methods: {
      // 设置填充颜色
      setNowColor(color) {
        if (color === 'space') {
          this.$emit('change', '')
          this.currColor = 'space'
          return
        }
        this.currColor = color
        this.$emit('change', color)
      },
    }
  }
</script>
  
<style lang='less' scoped>
  .color-pick{
    .scroll {
      white-space: nowrap;
      overflow-x: auto;
      margin: 0 .24rem;
    }
    .color-space {
      position: relative;
    }
    .inner {
      padding: 0.24rem 0.10rem;
    }
    
    span {
      display: inline-block;
      width: 0.6rem;
      height: 0.6rem;
      margin-right: 0.14rem;
      border-radius: 1rem;
      box-sizing: border-box;
      &.color-item {
        width: .5rem;
        height: .5rem;
        border: 1px solid #000;
      }
      &:last-child{
        margin-right: 0;
      }
      &.active {
        background-image: url("./image/tub-7.png");
        background-position: center;
        background-size: cover;
      }
    }
    
  }
</style>