import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "design-tab" }
const _hoisted_2 = {
  key: 1,
  class: "loading"
}

import { ref, reactive, getCurrentInstance } from 'vue'
import { Empty, showConfirmDialog, Loading } from 'vant'
import DesginItem from './item.vue'
import Image from '@/assets/images/九巴重機_ALL-01-frame-03-01 1.png'
import { IImageSprite, ILayerItem, IParams, ITextSprite } from '@/types'
import { getDesignApi } from '@/api/common'
import { getIntegrateUrl, getSimpleImageTemplateUrl } from '../utils'
import { useRoute } from 'vue-router'
// import temJson from '@/data/tem1.json'
// import temJson2 from '@/data/tem2.json'
// import temJson3 from '@/data/tem3.json'
// import temJson4 from '@/data/tem4.json'


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
  app: {
    type: Object,
    default() {
      return {}
    }
  },
  productInfo: {
    type: Object,
  },
},
  emits: ['setLoading'],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props
const emits = __emit
const route = useRoute();

const onLoadDesign = (data: IParams) => {
  if (props.app?.img_container.children.length) {
    showConfirmDialog({
      title: '提示',
      message: '是否覆盖所有设计？',
    })
      .then(() => {
        // on confirm
        emits('setLoading', true, '加载作品中...')
        props.app?.loadDesign?.(data, true, () => emits('setLoading', false))
      })
      .catch(() => {
        // on cancel
        emits('setLoading', true, '加载作品中...')
        props.app?.loadDesign?.(data, false, () => emits('setLoading', false))
      });
  } else {
    emits('setLoading', true, '加载作品中...')
    props.app?.loadDesign?.(data, true, () => emits('setLoading', false))
  }
}
const design = reactive<{
  list: ILayerItem[]
}>({
  list: [
    // temJson,
    // temJson2,
    // temJson3,
    // temJson4,
  ]
})

const getJson = (url: string) => {
  // const currentInstance = getCurrentInstance() as any
  // const { $request } = currentInstance.appContext.config.globalProperties;
  // return $request({
  //   url,
  //   method: 'GET'
  // })
  return getDesignApi({
    page: 1,
    limit: 1000,
    publish_status: 'published'
  })
}
const showLoading = ref(false)
const getJsonList = async () => {
  try {
    showLoading.value = true
    console.log(props.productInfo)
    const data = (await getDesignApi({
      page: 1,
      limit: 1000,
      publish_status: 'published',
      // product_id: props.productInfo?.productid,
      app_id: `${route.query.app_id}`,
    })).map(t => {
      t.effect_image = getSimpleImageTemplateUrl(t.effect_image || '')
      console.log('t.effect_image', t.effect_image)
      return t
    })
    showLoading.value = false
    design.list = data
  } catch(err) {

  }
}
getJsonList()

__expose({})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (design.list && design.list.length)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(design.list, (item, index) => {
          return (_openBlock(), _createBlock(DesginItem, {
            key: index,
            item: item,
            onOnLoad: onLoadDesign
          }, null, 8, ["item"]))
        }), 128))
      : (showLoading.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_unref(Loading), {
              color: "#000000",
              size: "36"
            })
          ]))
        : (_openBlock(), _createBlock(_unref(Empty), {
            key: 2,
            "image-size": "2.1rem",
            description: "暂无设计模板"
          }))
  ]))
}
}

})