import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, withKeys as _withKeys, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text" }
const _hoisted_2 = { class: "text-input" }
const _hoisted_3 = {
  class: "flex items-align gap-4",
  style: {"padding":".2rem","font-size":".36rem"}
}
const _hoisted_4 = { class: "custom-button" }
const _hoisted_5 = {
  class: "flex items-align gap-4",
  style: {"padding":".2rem"}
}
const _hoisted_6 = { class: "custom-button" }

import { ref, reactive, watch, onMounted } from 'vue'
import { Field, Slider } from 'vant'
import DemoImage from '@/assets/images/11.png'
import TabOutside from '@/components/TabOutside/index.vue'
import FontList from '@/components/FontList/index.vue'
import ColorPick from '@/components/ColorPick/index.vue'
import { IConfigFontStyle, ITextSprite } from '@/types'
import Pixi from '@/views/pixi/components/core'
import { useI18n } from 'vue-i18n';

const WEIGHT_MIN = 400
const WEIGHT_MAX = 700
const BORDER_WIDTH_MIN = 0
const BORDER_WIDTH_MAX = 10


export default /*@__PURE__*/_defineComponent({
  __name: 'textTab',
  props: {
  app: {
    type: Object
  },
  
},
  emits: ['onAdd', 'setLoading'],
  setup(__props, { expose: __expose, emit: __emit }) {

const { t, locale } = useI18n();
const props = __props
const emits = __emit
const value = ref<string>('')
const getResetStyle = (): IConfigFontStyle => {
  return {
    fontFamily: '',
    fontWeight: 100,
    strokeThickness: 0,
    stroke: '',
    fill: '',
  }
}
const fontStyle = ref<IConfigFontStyle>(getResetStyle())
// 字体颜色
const handleFontColorChange = (color: string) => {
  fontStyle.value.fill = color
  updateSprite()
}
// 边框颜色
const handleFontStrokeChange = (color: string) => {
  fontStyle.value.stroke = color
  updateSprite()
}
// 背景
const handleFontBgChange = (color: string) => {
  // fontStyle.value.bgColor = color
  updateSprite()
}
// 字体改变
const handleFontFamilyChange = (name: string) => {
  fontStyle.value.fontFamily = name
  updateSprite()
  // console.log('font change')
}

const hasFocus = ref<boolean>(false)
const handleFocus = () => {
  hasFocus.value = true
}
const handleBlur = () => {
  hasFocus.value = false
}
const handleEnter = () => {
  if (type.value === 'add') {
    emits('onAdd', value.value, fontStyle.value)
  } else {
    updateSprite()
  }
  // @ts-ignore
  document.querySelector('#textInput input')?.blur()
}

const setNotifyLoading = (val: boolean) => {
  emits('setLoading', val)
}

const type = ref<'add' | 'edit'>('add')
const updateSprite = () => {
  const { target } = props.app as any
  if (!target.sprite) {
    return
  }
  props.app?.historyRecord('update', [target.sprite])
  props.app?.updateTextSprite(target.sprite, value.value, fontStyle.value)
}

const familyRef = ref<any>(null)
const updateComponentData = (sprite: ITextSprite | null) => {
  familyRef.value?.update(sprite ? sprite.style.fontFamily : '')
}
const update = () => {
  const { target } = props.app as Pixi
  if (!target.sprite) {
    type.value = 'add'
    fontStyle.value = getResetStyle()
    return
  }

  const sprite = target.sprite as ITextSprite
  if (sprite.type !== 'text') {
    return
  }
  type.value = 'edit'
  value.value = sprite?.text || ''
  fontStyle.value = {
    fontFamily: sprite.style.fontFamily as string,
    fontWeight: sprite.style.fontWeight || 100,
    strokeThickness: sprite.style.strokeThickness,
    stroke: sprite.style.stroke,
    fill: sprite.style.fill as any,
  }
  updateComponentData(sprite)
}
update()

const reset = () => {
  type.value = 'add'
  value.value = ''
  fontStyle.value = getResetStyle()
}

const handleWeightChange = (weight: number) => {
  const { target } = props.app as any
  if (!target.sprite) {
    return
  }
  props.app?.historyRecord('update', [target.sprite])
  props.app?.updateTextWeight(target.sprite, weight)
}

const handleStrokeThicknessChange = (weight: number) => {
  const { target } = props.app as any
  if (!target.sprite) {
    return
  }
  props.app?.historyRecord('update', [target.sprite])
  props.app?.updateTextStroke(target.sprite, fontStyle.value)
}

__expose({
  update,
  reset
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["text-input--wrap", { focus: hasFocus.value }]),
        id: "textInput"
      }, [
        _createVNode(_unref(Field), {
          modelValue: value.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((value).value = $event)),
          label: "",
          placeholder: _unref(t)('text_hint'),
          onFocus: handleFocus,
          onBlur: handleBlur,
          onKeydown: _withKeys(handleEnter, ["enter"])
        }, null, 8, ["modelValue", "placeholder"]),
        _createElementVNode("span", { onClick: handleEnter }, _toDisplayString(_unref(t)('confirm')), 1)
      ], 2)
    ]),
    _createVNode(TabOutside, {
      title: _unref(t)('font_family')
    }, {
      default: _withCtx(() => [
        _createVNode(FontList, {
          ref_key: "familyRef",
          ref: familyRef,
          onChange: handleFontFamilyChange,
          onSetLoading: setNotifyLoading
        }, null, 512)
      ]),
      _: 1
    }, 8, ["title"]),
    _createVNode(TabOutside, {
      title: _unref(t)('font_color')
    }, {
      default: _withCtx(() => [
        _createVNode(ColorPick, {
          value: fontStyle.value.fill,
          hasOpacity: false,
          onChange: handleFontColorChange
        }, null, 8, ["value"])
      ]),
      _: 1
    }, 8, ["title"]),
    _createVNode(TabOutside, {
      title: _unref(t)('font_weight')
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", null, _toDisplayString(WEIGHT_MIN)),
          _createVNode(_unref(Slider), {
            modelValue: fontStyle.value.fontWeight,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((fontStyle.value.fontWeight) = $event)),
            onChange: handleWeightChange,
            "active-color": "#fcb913",
            step: 100,
            min: WEIGHT_MIN,
            max: WEIGHT_MAX
          }, {
            button: _withCtx(() => [
              _createElementVNode("div", _hoisted_4, _toDisplayString(fontStyle.value.fontWeight), 1)
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _createElementVNode("span", null, _toDisplayString(WEIGHT_MAX))
        ])
      ]),
      _: 1
    }, 8, ["title"]),
    _createVNode(TabOutside, {
      title: _unref(t)('font_board')
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("span", null, _toDisplayString(BORDER_WIDTH_MIN)),
          _createVNode(_unref(Slider), {
            modelValue: fontStyle.value.strokeThickness,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((fontStyle.value.strokeThickness) = $event)),
            onChange: handleStrokeThicknessChange,
            "active-color": "#fcb913",
            step: 1,
            min: BORDER_WIDTH_MIN,
            max: BORDER_WIDTH_MAX
          }, {
            button: _withCtx(() => [
              _createElementVNode("div", _hoisted_6, _toDisplayString(fontStyle.value.strokeThickness), 1)
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _createElementVNode("span", null, _toDisplayString(BORDER_WIDTH_MAX))
        ]),
        _createVNode(ColorPick, {
          value: fontStyle.value.stroke,
          hasOpacity: false,
          onChange: handleFontStrokeChange
        }, null, 8, ["value"])
      ]),
      _: 1
    }, 8, ["title"])
  ]))
}
}

})