import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "font-image" }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 0,
  class: "font-select"
}

import { ref, reactive } from 'vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'item',
  props: {
  item: {
    type: Object,
    deafult() {
      return {}
    }
  },
},
  emits: ['onSelect'],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props
const emits = __emit

const onClick = () => {
  emits('onSelect', props.item)
}

__expose({})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "font",
    onClick: onClick
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        src: __props.item.imgUrl,
        alt: ""
      }, null, 8, _hoisted_2)
    ]),
    (__props.item.checked)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[0] || (_cache[0] = [
          _createElementVNode("i", { class: "iconfont icon-dagou" }, null, -1)
        ])))
      : _createCommentVNode("", true)
  ]))
}
}

})