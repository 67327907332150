import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "bg-color" }
const _hoisted_2 = { class: "list" }
const _hoisted_3 = ["onClick"]

import { ref, reactive } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'bgColor',
  props: {
  data: {
    type: Array,
    default () {
      return []
    }
  }
},
  emits: ['onAdd'],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props
const emits = __emit
const handleAdd = (color: string) => {
  console.log(color)
  emits('onAdd', color.replace('#', ''))
}
const color = reactive({
  list: [
  ],
})
__expose({})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.data, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "item",
          key: index,
          onClick: ($event: any) => (handleAdd(item.colorvalue)),
          style: _normalizeStyle({ 'background-color': item.colorvalue })
        }, null, 12, _hoisted_3))
      }), 128))
    ])
  ]))
}
}

})