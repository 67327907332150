import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "many-side" }
const _hoisted_2 = { class: "list justify-center" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "name truncate w-full" }

import { ICoreAppList } from '@/views/pixi/types'
import { ref, reactive } from 'vue'
interface ManySideProps {
  list: ICoreAppList;
  current: number
}

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
    list: {},
    current: {}
  },
  emits: ['setManySide'],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const props = __props
const emits = __emit
const onSideClick = (index: number) => {
  emits('setManySide', index)
}

__expose({})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "item flex flex-col items-align gap-1",
          onClick: ($event: any) => (onSideClick(index))
        }, [
          _createElementVNode("p", _hoisted_4, _toDisplayString(item.sideName), 1),
          _createElementVNode("p", {
            class: _normalizeClass(["line", { active: index === _ctx.current }])
          }, null, 2)
        ], 8, _hoisted_3))
      }), 128))
    ])
  ]))
}
}

})