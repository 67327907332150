import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "scale-float flex flex-col gap-2" }

import { ref, reactive, watch } from 'vue'
import { Icon } from 'vant'
import { IScaleFloatType } from '../../types'
import { useRouter } from 'vue-router'
type FlotItemType = 'cart'

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
  shopType: {
    type: String
  }
},
  emits: ['setCoreScale'],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props
const emits = __emit
const router = useRouter()
const current = ref<IScaleFloatType>('plus')

const onClick = (type: FlotItemType) => {
  switch(type) {
    case 'cart':
      router.push('/cart')
      break
    default:
      break
  }
}

const getIconClassName = () => {
  switch(current.value) {
    case 'plus':
      return 'icon-plus1'
    case 'reduce':
      return 'icon-jianhao'
  }
}
const iconClassName = ref<string>(getIconClassName())
watch(() => current, () => {
  iconClassName.value = getIconClassName()
})

__expose({})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (__props.shopType !== 'SY')
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "icon-btn flex items-align justify-center",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (onClick('cart')))
        }, [
          _createVNode(_unref(Icon), {
            name: "cart-o",
            size: ".4rem"
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})