import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref } from "vue"

const _hoisted_1 = { class: "layer-tab" }

import { ref, reactive } from 'vue'
import { Empty } from 'vant'
import LayerItem from './item.vue'
import Image from '@/assets/images/九巴重機_ALL-01-frame-03-01 1.png'
import { IImageSprite, ILayerItem, ISpriteType, ITextSprite } from '@/types'
import { getSpriteTypeValue } from '../utils'
import { prop } from 'vue-class-component'


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
  app: {
    type: Object,
    default() {
      return {}
    }
  }
},
  emits: [],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props
const emits = __emit


const layer = reactive<{
  list: ILayerItem[]
}>({
  list: []
})

const updateLayer = () => {
  const { children }  = props.app?.img_container
  layer.list = children.map((t: IImageSprite | ITextSprite) => {
    console.log(t)
    return {
      type: t.type,
      uuid: t.uuid,
      show: t.visible,
      lock: t.interactive,
      // @ts-ignore
      bgType: props.app?.hasBgSprite(t),
      value: getSpriteTypeValue(t.type, t)
    }
  })
}
updateLayer()

const onToggleShowSprite = (item: ILayerItem) => {
  const visible = props.app?.toggleShowSprite?.(item.uuid)
  item.show = visible
}

const onToggleLockSprite = (item: ILayerItem) => {
  const visible = props.app?.toggleLockSprite?.(item.uuid)
  item.lock = visible
}

const onDelSprite = (item: ILayerItem) => {
  props.app?.remove?.(item.uuid)
  let index = layer.list.findIndex((t) => t.uuid === item.uuid)
  if (index !== -1) {
    layer.list.splice(index, 1)
  }
}

const onSelectSprite = (item: ILayerItem) => {
  props.app?.selectSprite?.(item.uuid)
}

__expose({
  update: () => {
    updateLayer()
  }
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (layer.list && layer.list.length)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(layer.list, (item) => {
          return (_openBlock(), _createBlock(LayerItem, {
            key: item.uuid,
            item: item,
            onOnShow: onToggleShowSprite,
            onOnDel: onDelSprite,
            onOnLock: onToggleLockSprite,
            onOnSelect: onSelectSprite
          }, null, 8, ["item"]))
        }), 128))
      : (_openBlock(), _createBlock(_unref(Empty), {
          key: 1,
          "image-size": "2.1rem",
          description: "暂无图层"
        }))
  ]))
}
}

})