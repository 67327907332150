import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tab-outside" }

import { ref, reactive, watch, getCurrentInstance, getCurrentScope } from 'vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
  title: {
    type: String,
    default: ''
  },
  control: {
    type: Boolean
  },
  contentHeight: String,
  defaultVisible: {
    type: Boolean
  },
},
  emits: ['onChange'],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props
const emits = __emit

const showVisible = ref<boolean>(!!props.defaultVisible)
const handleSwitch = () => {
  const value = !showVisible.value
  if (props.control) {
    emits('onChange', value, (v: boolean) => {
      showVisible.value = v
    })
  } else {
    showVisible.value = value
  }
}
// watch(() => props.visible, (val) => {
//   showVisible.value = val
// })

__expose({})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "header",
      onClick: handleSwitch
    }, [
      _createElementVNode("p", null, _toDisplayString(__props.title), 1),
      _createElementVNode("i", {
        class: _normalizeClass({ active: showVisible.value })
      }, null, 2)
    ]),
    _createElementVNode("div", {
      class: "content",
      style: _normalizeStyle({ display: showVisible.value ? '' : 'none', height: __props.contentHeight })
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 4)
  ]))
}
}

})