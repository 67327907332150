import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "subject p-[.2rem]" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["src"]

import { ref, reactive } from 'vue'
import { ISubject } from '@/types'
import SubjectImage from '@/assets/images/subject-01.png'


export default /*@__PURE__*/_defineComponent({
  __name: 'subject',
  props: {
  type: String,
  seleced: Object,
  list: {
    type: Array,
    default() {
      return []
    }
  },
},
  emits: ['onSubjectClick'],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props
const emits = __emit

const handleClick = (item: ISubject) => {
  emits('onSubjectClick', item)
}

__expose({})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["list", { 'type-row': __props.type === 'row' }])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.list, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["item p-[.1rem]", { active: __props.seleced?.id === item.id }]),
          key: index,
          onClick: ($event: any) => (handleClick(item))
        }, [
          _createElementVNode("img", {
            src: item.thumbnail,
            alt: ""
          }, null, 8, _hoisted_3)
        ], 10, _hoisted_2))
      }), 128))
    ], 2)
  ]))
}
}

})