import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "font-list" }
const _hoisted_2 = { class: "list" }

import { ref, reactive } from 'vue'
import FontItem from './components/item.vue'
interface IFontItem {
  url: string;
  imgUrl: string;
  checked: boolean
  name: string;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {},
  emits: ['change', 'setLoading'],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props
const emits = __emit

const font = reactive<{
  list: IFontItem[]
}>({
  list: [
    {
      url: 'https://web.plugcustom.com.cn/plug-fonts/SourceHanSansCN-Bold.otf',
      name: '思源黑体',
      imgUrl: 'https://web.plugcustom.com.cn/plug-fonts/%E6%80%9D%E6%BA%90%E9%BB%91%E4%BD%93.png',
      checked: false,
    },
    {
      url: 'https://web.plugcustom.com.cn/plug-fonts/SoukouMincho.ttf',
      name: '装甲明朝体',
      imgUrl: 'https://web.plugcustom.com.cn/plug-fonts/%E8%A3%85%E7%94%B2%E6%98%8E%E6%9C%9D%E4%BD%93.png',
      checked: false,
    },
    {
      url: 'https://web.plugcustom.com.cn/plug-fonts/%E4%BB%93%E8%80%B3%E8%88%92%E5%9C%86%E4%BD%93W03.ttf',
      name: '仓耳舒圆体',
      imgUrl: 'https://web.plugcustom.com.cn/plug-fonts/%E4%BB%93%E8%80%B3%E8%88%92%E5%9C%86%E4%BD%93.png',
      checked: false,
    },
    {
      url: 'https://web.plugcustom.com.cn/plug-fonts/%E6%B1%9F%E5%9F%8E%E6%AD%A3%E4%B9%89%E4%BD%93%20400W.ttf',
      name: '江城正义体',
      imgUrl: 'https://web.plugcustom.com.cn/plug-fonts/%E6%B1%9F%E5%9F%8E%E6%AD%A3%E4%B9%89%E4%BD%93.png',
      checked: false,
    },
  ]
})

const onFontSelect = async (item: IFontItem) => {
  const val = !item.checked
  font.list = font.list.map((t) => {
    if (t === item) {
      return {
        ...t,
        checked: val
      }
    }
    return {
      ...t,
      checked: false
    }
  })

  requestAnimationFrame(async () => {
    if (val) {
      emits('setLoading', true)
      await downLoadFont(item.name, item.url)
    }
    emits('change', val ? item.name : '')
    emits('setLoading', false)
  })
}
const downLoadFont = async (name: string,url: string) => {
  const fontFace = new FontFace(name, `url(${url})`)
  try {
    const loadedFontFace = await fontFace.load()
    document.fonts.add(loadedFontFace)
    console.log('字体加载完成')
  } catch(err) {
    console.log(err)
    console.log('字体加载失败')
  }
}

__expose({
  update: (name: string) => {
    console.log('111', name)
    font.list = font.list.map((t) => ({
      ...t,
      checked: t.name === name
    }))
  }
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("div", null, null, -1)),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(font.list, (item, index) => {
        return (_openBlock(), _createBlock(FontItem, {
          key: index,
          item: item,
          onOnSelect: onFontSelect
        }, null, 8, ["item"]))
      }), 128))
    ])
  ]))
}
}

})