import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "cut-image" }
const _hoisted_2 = {
  key: 0,
  class: "cut-image-head"
}
const _hoisted_3 = { class: "cut-image-body" }
const _hoisted_4 = {
  key: 1,
  class: "cut-image-foot"
}
const _hoisted_5 = { class: "cut-image-foot__wrap" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "cut-image-foot__itemIcon" }

import { ref, reactive, onMounted, watch } from 'vue'
import * as PIXI from 'pixi.js'
import { Dialog } from 'vant';
import type { ISizeInfo, ITarget, IType } from './types'
import { loadImage } from '@/utils'
import { Graphics, Sprite } from 'pixi.js';


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
  value: {
    type: String,
  }
},
  emits: ['onCutDone'],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props
const emits = __emit

const cutImageRef = ref<any>(null)
const app = ref<PIXI.Application & {originStageWidth?: number;originStageHeight?: number}>(new PIXI.Application())
const border = ref<PIXI.Graphics>(new PIXI.Graphics())
const lines = ref<Graphics[]>([])
const borderObj = ref({
  width: 0,
  height: 0,
})
const imgInfo = ref({
  width: 0,
  height: 0
})
const originImgInfo = ref({
  width: 0,
  height: 0,
})
const sprite = ref<PIXI.Sprite & {originBeforeX?: number} | null>(null)
const drawArea = ref<Sprite | null>(null)
const target = ref<ITarget>({
  sprite: null,
  type: undefined
})
const graphList = ref([
  { className: 'style-1', name: '原始', icon: 'icon-a-zhazeng1', active: false, },
  { className: 'style-1', name: '正方形', icon: 'icon-zhengfangxing', active: false, },
  { className: 'style-2', name: '2:3', icon: 'icon-a-2-31', active: false, },
  { className: 'style-3', name: '3:2', icon: 'icon-a-3-21', active: false, },
  { className: 'style-4', name: '3:4' , icon: 'icon-a-3-41', active: false, },
  { className: 'style-4', name: '4:3' , icon: 'icon-a-4-31', active: false, },
  { className: 'style-4', name: '9:16' , icon: 'icon-a-9-161', active: false, },
  { className: 'style-4', name: '16:9' , icon: 'icon-a-16-91', active: false, },
])
const rightBottomSpot = ref<Graphics | null>(null)
const rightTopSpot = ref<Graphics | null>(null)
const leftTopSpot = ref<Graphics | null>(null)
const leftBottomSpot = ref<Graphics | null>(null)
const cutImage = ref({
  value: '',
  file: '',
  show: false,
  url: ''
})

const scaleToMaxWH = (scaleW: number, scaleH: number, w: number, h: number) => {
  let width = 0, height = 0
  if (scaleW > scaleH) {
    let diff = scaleH / scaleW
    width = w
    if (w * diff > h) {
      width = w - ((w * diff - h) * (scaleW / scaleH))
    }
    height = width * diff
  } else if (scaleW < scaleH) {
    let diff = scaleW / scaleH
    height = h
    if (h * diff > w) {
      height = h - ((h * diff - w) * (scaleH / scaleW))
    }
    width = height * diff
  } else {
    let min = Math.min(w, h) * .8
    width = min
    height = min
  }
  return { width, height }
}
const animationPlay = (time: number, { w, h, x, y }: { w: number; h: number; x: number; y: number; }) => {
  if (!drawArea.value) {
    return
  }
  const playTime = time / 16.7
  const playAll = Math.round(playTime)
  let currW = drawArea.value.width, currH = drawArea.value.height, currX = drawArea.value.x, currY = drawArea.value.y
  let onceW = (w - currW) / playTime, startW = 0
  let onceH = (h - currH) / playTime, startH = 0
  let onceX = (x - currX) / playTime, startX = 0
  let onceY = (y - currY) / playTime, startY = 0
  let i = 0
  // return
  const play = () => {
    startW += onceW
    startH += onceH
    startX += onceX
    startY += onceY
    updateAreaScaleInfo(currW + startW, currH + startH, currX + startX, currY + startY)
    i++
    if (i === playAll) {
      return
    }
    window.requestAnimationFrame(play)
  }
  window.requestAnimationFrame(play)
}

const handleSwitch = (item: { active: boolean; name: string; }) => {
  if (!sprite.value) {
    return
  }
  let info = { width: 0, height: 0 }
  graphList.value.forEach(t => {
    t.active = false
  })
  item.active = true
  switch(item.name) {
    case '2:3':
      info = scaleToMaxWH(2, 3, sprite.value.width, sprite.value.height)
      break
    case '3:2':
      info = scaleToMaxWH(3, 2, sprite.value.width, sprite.value.height)
      break
    case '3:4':
      info = scaleToMaxWH(3, 4, sprite.value.width, sprite.value.height)
      break
    case '4:3':
      info = scaleToMaxWH(4, 3, sprite.value.width, sprite.value.height)
      break
    case '9:16':
      info = scaleToMaxWH(9, 16, sprite.value.width, sprite.value.height)
      break
    case '16:9':
      info = scaleToMaxWH(16, 9, sprite.value.width, sprite.value.height)
      break
    case '原始':
      info = { width: sprite.value.width, height: sprite.value.height }
      break
    case '正方形':
      info = scaleToMaxWH(1, 1, sprite.value.width, sprite.value.height)
      break
    default:
      break
  }
  let x = sprite.value.x + (sprite.value.width / 2) - (info.width / 2), y = sprite.value.y + (sprite.value.height / 2) - (info.height / 2)
  
  if (item.name === '原始') {
    x = sprite.value.x
    y = sprite.value.y
  }
  console.log({ w: info.width, h: info.height, x, y }, '---')
  animationPlay(300, { w: info.width, h: info.height, x, y })
}

const handleCancel = () => {
  cutImage.value.value = ''
  cutImage.value.show = false
  reset()
}

const setPositionSpotVisible = (val: boolean) => {
  if (!leftTopSpot.value || !leftBottomSpot.value || !rightTopSpot.value || !rightBottomSpot.value) {
    return
  }
  leftTopSpot.value.visible = val
  leftBottomSpot.value.visible = val
  rightTopSpot.value.visible = val
  rightBottomSpot.value.visible = val
}
const saveCallback = (data: string) => {
  if (!drawArea.value || !sprite.value) {
    return
  }
  emits('onCutDone', data)
  cutImage.value.value = ''
  cutImage.value.show = false
  sprite.value.originBeforeX = undefined
  reset()
}
const handleSave = () => {
  if (!drawArea.value || !sprite.value) {
    return
  }
  drawArea.value.width
  let dWid = drawArea.value.width,
    dHei = drawArea.value.height,
    sWid = sprite.value.width,
    sHei = sprite.value.height
  
  if (dWid === sWid && dHei === sHei) {
    saveCallback(props.value as string)
    return
  }
  console.log(2)
  let { width, height } = originImgInfo.value
  width = width / 2
  height = height / 2
  let multiple = width / app.value.stage.width

  sprite.value.originBeforeX = sprite.value.x
  sprite.value.x = 0

  app.value.originStageWidth = app.value.stage.width
  app.value.originStageHeight = app.value.stage.height
  app.value.stage.scale.set(multiple)
  drawArea.value.visible = false
  border.value.visible = false
  setPositionSpotVisible(false)
  lines.value.forEach(line => {
    line.visible = false
  })

  let renderTexture = PIXI.RenderTexture.create(width, height)
  // @ts-ignore
  app.value.renderer.render(app.value.stage, renderTexture)
  const stageImage = app.value.renderer.plugins.extract.image(renderTexture, 'image/png', 1)
  drawArea.value.visible = true
  border.value.visible = true
  setPositionSpotVisible(true)
  lines.value.forEach(line => {
    line.visible = true
  })
  app.value.stage.width = app.value.originStageWidth
  app.value.stage.height = app.value.originStageHeight
  sprite.value.x = sprite.value.originBeforeX
  stageImage.onload = () => {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    if (!ctx || !drawArea.value || !sprite.value) {
      return
    }
    let { width, height } = borderObj.value
    width *= multiple
    height *= multiple
    canvas.width = width
    canvas.height = height
    ctx.drawImage(
      stageImage,
      (drawArea.value.x - (sprite.value?.originBeforeX || 0)) * multiple,
      drawArea.value.y * multiple,
      width,
      height,
      0,
      0,
      width,
      height
    )

    const data = canvas.toDataURL('image/png', 1)
    saveCallback(data)
  }
}

const init = (base64: string) => {
  // let { file, url } = this.cutImage
  // if (url) {
  //   this.loadImage(url).then(info => {
  //     this.initCanvas(url, info)
  //   })
  // } else if (file) {
  //   file.arrayBuffer().then(val => {
  //     let base64 = `data:image/png;base64,${arrayBufferToBase64(val)}`
  //     // this.initCanvas()
  //     this.loadImage(base64).then(info => {
  //       this.initCanvas(base64, info)
  //     })
  //   })
  // }
  // console.log(base64, '--')
  cutImage.value.value = base64
  cutImage.value.show = true
  loadImage(base64).then(info => {
    initCanvas(base64, info)
  })
}

const initCanvas = (url: string, info: any) => {
  // if (!drawArea.value || !sprite.value) {
  //   return
  // }
  const scale = (window.innerWidth * .98) / info.width
  let width = info.width * scale
  let height = info.height * scale
  const application = new PIXI.Application({
    width: window.innerWidth,
    height: height + 20,
    antialias: true,
    transparent: true,
    resolution: 3
  })

  application.view.style.backgroundColor = 'transparent'
  application.view.style.display = 'block'
  application.view.id = 'cutImageRef'
  cutImageRef.value.appendChild(application.view)
  application.view.style.width = window.innerWidth + 'px'
  application.view.style.height = (height + 20) + 'px'
  app.value = application


  const imgSprite = PIXI.Sprite.from(url)
  imgSprite.width = width
  imgSprite.height = height
  imgSprite.position.set((window.innerWidth - width) / 2, 10)
  sprite.value = imgSprite
  // sprite.texture.baseTexture.on('loaded', () => {})
  application.stage.addChild(imgSprite)

  const container = new PIXI.Sprite()
  drawArea.value = container
  application.stage.addChild(container)

  borderObj.value = {
    width: width,
    height: height
  }
  imgInfo.value = {
    width,
    height
  }
  originImgInfo.value = info

  container.x = (width / 2) - (borderObj.value.width / 2)
  container.y = (height / 2) - (borderObj.value.height / 2)
  container.width = borderObj.value.width
  container.height = borderObj.value.height
  container.position.set(imgSprite.x, imgSprite.y)
  
  drawBorder(container, borderObj.value)

  initZoomSpot('rightBottom', container, borderObj.value)
  initZoomSpot('rightTop', container, borderObj.value)
  initZoomSpot('leftTop', container, borderObj.value)
  initZoomSpot('leftBottom', container, borderObj.value)
  drawLine(container, borderObj.value)

  initEvent()
}

const initEvent = () => {
  const application = app.value
  const container = drawArea.value
  if (!container) {
    return
  }
  let startX = 0, startY = 0, startContainerX = 0, startContainerY = 0, startContainerWid = 0, startContainerHei = 0, hasTarget = false
  application.view.addEventListener("touchstart", (e) => {
    let { clientX, clientY } = e.touches[0]

    startX = clientX
    startY = clientY
    startContainerX = container.x
    startContainerY = container.y
    startContainerWid = container.width
    startContainerHei = container.height

    if (target.value.sprite) {
      hasTarget = true
    } else {
      hasTarget = false
    }
  })
  application.view.addEventListener("touchend", (e: any) => {
    target.value = {
      sprite: null,
      type: undefined
    }
  })
  application.view.addEventListener("touchmove", (e) => {
    if (!sprite.value) {
      return
    }
    let { clientX, clientY } = e.touches[0]
    let x = clientX - startX + startContainerX,
    y = clientY - startY + startContainerY

    // 缩放选区
    console.log(hasTarget, '-hasTarget')
    if (hasTarget) {
      let add = 0, w = 0, h = 0, addWid = 0, addHei = 0
      graphList.value.forEach(t => {
        t.active = false
      })
      switch(target.value.type) {
        case 'right':
          add = clientX - startX
          w = startContainerWid + add
          if (container.x + w >= sprite.value.x + sprite.value.width) {
            w = (sprite.value.width + sprite.value.x) - container.x
          }
          updateAreaScaleInfo(w, container.height)
          break
        case  'bottom':
          add = clientY - startY
          h = startContainerHei + add
          if (container.y + h >= sprite.value.y + sprite.value.height) {
            h = (sprite.value.height + sprite.value.y) - container.y
          }
          updateAreaScaleInfo(container.width, h)
          break
        case 'left':
          add = startX - clientX
          w = startContainerWid + add
          updateAreaScaleInfo(w, container.height, startContainerX + -(add))
          break
        case 'top':
          add = startY - clientY
          h = startContainerHei + add
          updateAreaScaleInfo(container.width, h, undefined, startContainerY + -(add))
          break
        case 'rightBottom':
          addWid = clientX - startX
          w = startContainerWid + addWid
          if (container.x + w >= sprite.value.x + sprite.value.width) {
            w = (sprite.value.width + sprite.value.x) - container.x
          }
          addHei = clientY - startY
          h = startContainerHei + addHei
          if (container.y + h >= sprite.value.y + sprite.value.height) {
            h = (sprite.value.height + sprite.value.y) - container.y
          }
          updateAreaScaleInfo(w, h)
          break
        case 'rightTop':
          addWid = clientX - startX
          w = startContainerWid + addWid
          if (container.x + w >= sprite.value.x + sprite.value.width) {
            w = (sprite.value.width + sprite.value.x) - container.x
          }

          addHei = startY - clientY
          h = startContainerHei + addHei
          updateAreaScaleInfo(w, h, undefined, startContainerY + -(addHei))
          break
        case 'leftBottom':
          addHei = clientY - startY
          h = startContainerHei + addHei
          if (container.y + h >= sprite.value.y + sprite.value.height) {
            h = (sprite.value.height + sprite.value.y) - container.y
          }

          addWid = startX - clientX
          w = startContainerWid + addWid
          updateAreaScaleInfo(w, h, startContainerX + -(addWid))
          break
        case 'leftTop':
          addHei = startY - clientY
          h = startContainerHei + addHei

          addWid = startX - clientX
          w = startContainerWid + addWid
          updateAreaScaleInfo(w, h, startContainerX + -(addWid), startContainerY + -(addHei))
          break
      }
      return
    }

    let zeroX = sprite.value.x, zeroY = sprite.value.y
    if (x <= zeroX) {
      x = zeroX
    } else if (x + borderObj.value.width > imgInfo.value.width + zeroX) {
      x = imgInfo.value.width - borderObj.value.width + zeroX
    }

    if (y <= zeroY) {
      y = zeroY
    } else if (y + borderObj.value.height > imgInfo.value.height + zeroY) {
      y = imgInfo.value.height - borderObj.value.height + zeroY
    }
    container.x = x
    container.y = y
    updateAreaScaleInfo(container.width, container.height)
  })
}

const updateAreaScaleInfo = (w: number, h: number, x?: number, y?: number) => {
  if (!borderObj.value || !drawArea.value) {
    return
  }
  borderObj.value.width = w
  borderObj.value.height = h
  drawArea.value.width = w
  drawArea.value.height = h

  if (typeof x === 'number') {
    drawArea.value.x = x
  }
  if (typeof y === 'number') {
    drawArea.value.y = y
  }

  drawBorder(drawArea.value)
  drawZoomSpot(rightBottomSpot.value, 'rightBottom', drawArea.value, w, h)
  drawZoomSpot(rightTopSpot.value, 'rightTop', drawArea.value, w, h)
  drawZoomSpot(leftTopSpot.value, 'leftTop', drawArea.value, w, h)
  drawZoomSpot(leftBottomSpot.value, 'leftBottom', drawArea.value, w, h)
  drawLine(drawArea.value)
}

const drawZoomSpot = (graphics: any, type: string|undefined, container: any, width: number, height: number) => {
  let lineHeight = 30, lineWidth = 30, lineNum = 6, startX = 0, startY = 0
  let lineNumHalf = lineNum / 2
  graphics.clear()
  graphics.beginFill(0xffffff)
  switch(type) {
    case 'right':
      startX = width - (lineNumHalf) + container.x
      startY = (height / 2) - (lineHeight / 2) + container.y

      graphics.drawRect(startX, startY, lineNum, lineHeight)

      graphics.maskSprite.width = lineNum * 2
      graphics.maskSprite.height = lineHeight * 2
      graphics.maskSprite.x = startX - (lineNumHalf)
      graphics.maskSprite.y = startY - (lineHeight / 2)
      break
    case 'left':
      startX = -(lineNumHalf) + container.x
      startY = (height / 2) - (lineHeight / 2) + container.y

      graphics.drawRect(startX, startY, lineNum, lineHeight)

      graphics.maskSprite.width = lineNum * 2
      graphics.maskSprite.height = lineHeight * 2
      graphics.maskSprite.x = startX - (lineNumHalf)
      graphics.maskSprite.y = startY - (lineHeight / 2)
      break
    case 'top':
      startX = (width / 2) - (lineWidth / 2) + container.x
      startY = 0 - (lineNumHalf) + container.y

      graphics.drawRect(startX, startY, lineWidth, lineNum)

      graphics.maskSprite.width = lineWidth * 2
      graphics.maskSprite.height = lineNum * 2
      graphics.maskSprite.x = startX - (lineWidth / 2)
      graphics.maskSprite.y = startY - (lineNumHalf)
      break
    case 'bottom':
      startX = (width / 2) - (lineWidth / 2) + container.x
      startY = height - (lineNumHalf) + container.y

      graphics.drawRect(startX, startY, lineWidth, lineNum)
      
      graphics.maskSprite.width = lineWidth * 2
      graphics.maskSprite.height = lineNum * 2
      graphics.maskSprite.x = startX - (lineWidth / 2)
      graphics.maskSprite.y = startY - (lineNumHalf)
      break
    case 'rightBottom':
      startX = width - (lineNumHalf) + container.x
      startY = height - lineHeight + container.y

      graphics.drawRect(startX, startY, lineNum, lineHeight)
      graphics.endFill()
      graphics.beginFill(0xffffff)
      graphics.drawRect(startX - lineWidth, startY + lineHeight - (lineNumHalf), lineWidth + lineNum, lineNum)

      graphics.maskSprite.width = lineWidth + lineNum 
      graphics.maskSprite.height = lineHeight + lineNumHalf
      graphics.maskSprite.x = startX - lineWidth
      graphics.maskSprite.y = startY
      break
    case 'rightTop':
      startX = (width) - (lineWidth) + container.x
      startY = 0 - (lineNumHalf) + container.y

      graphics.drawRect(startX, startY, lineWidth, lineNum)
      graphics.endFill()
      graphics.beginFill(0xffffff)
      graphics.drawRect(startX + lineWidth - lineNumHalf, startY, lineNum, lineHeight)

      graphics.maskSprite.width = lineWidth + lineNum 
      graphics.maskSprite.height = lineHeight + lineNumHalf
      graphics.maskSprite.x = startX
      graphics.maskSprite.y = startY
      break
    case 'leftTop':
      startX = container.x
      startY = container.y - lineNumHalf

      graphics.drawRect(startX, startY, lineWidth, lineNum)
      graphics.endFill()
      graphics.beginFill(0xffffff)
      graphics.drawRect(startX - lineNumHalf, startY, lineNum, lineHeight)

      graphics.maskSprite.width = lineWidth + lineNum 
      graphics.maskSprite.height = lineHeight + lineNumHalf
      graphics.maskSprite.x = startX - lineNumHalf
      graphics.maskSprite.y = startY
      break
    case 'leftBottom':
      startX = container.x - lineNumHalf
      startY = height - lineHeight + container.y

      graphics.drawRect(startX, startY, lineNum, lineHeight)
      graphics.endFill()
      graphics.beginFill(0xffffff)
      graphics.drawRect(startX, startY + lineHeight - lineNumHalf, lineWidth, lineNum)

      graphics.maskSprite.width = lineWidth + lineNum 
      graphics.maskSprite.height = lineHeight + lineNumHalf
      graphics.maskSprite.x = startX
      graphics.maskSprite.y = startY
      break
  }
  graphics.endFill()
}

const initZoomSpot = (type: IType, container: PIXI.Sprite, { width, height }: { width: number; height: number; }) => {
  // .from('https://img.moself.cn/new/material/20200504/7b170f58-210e-49cd-9627-f2be49c4378b.png~tplv-z8ialgywaa-re2:800.webp')
  let graphics: Graphics & {maskSprite?: Sprite} = new PIXI.Graphics(), maskSprite = new PIXI.Sprite()
  
  switch(type) {
    case 'rightBottom':
      rightBottomSpot.value = graphics
      break
    case 'rightTop':
      rightTopSpot.value = graphics
      break
    case 'leftTop':
      leftTopSpot.value = graphics
      break
    case 'leftBottom':
      leftBottomSpot.value = graphics
      break
  }
  graphics.maskSprite = maskSprite

  drawZoomSpot(graphics, type, container, width, height)
  maskSprite.interactive = true
  maskSprite.on('pointerdown', (event) => {
    // alert(11)
    target.value = {
      sprite: graphics,
      type
    }
  })
  app.value.stage.addChild(graphics)
  app.value.stage.addChild(maskSprite)
}

const drawBorder = (container: any, info?: ISizeInfo) => {
  if (!border.value) {
    let graphics = new PIXI.Graphics()
    border.value = graphics
    app.value.stage.addChild(graphics)
  }

  // let dropShadowFilter = new DropShadowFilter({
  //   alpha: 1,
  //   distance: 2,
  //   blur: 1,
  // })
  // border.filters = [dropShadowFilter]
  border.value.clear()
  border.value.lineStyle(2, 0xffffff, 1);
  border.value.drawRect(container.x, container.y, info?.width || container.width, info?.height || container.height)
}

const drawLine = (container: any, info?: ISizeInfo) => {
  if (lines.value.length === 0) {
    lines.value = [
      new PIXI.Graphics(),
      new PIXI.Graphics(),
      new PIXI.Graphics(),
      new PIXI.Graphics()
    ]
  } else {
    lines.value.forEach(line => {
      line.clear()
    })
  }
  let lineRowOne = lines.value[0] as Graphics
  app.value.stage.addChild(lineRowOne)
  let width = info?.width || container.width, height = info?.height || container.height
  lineRowOne.lineStyle(.5, 0xffffff, 1);
  lineRowOne.moveTo(container.x, height * .33 + container.y)
  lineRowOne.lineTo(container.x + width, height * .33 + container.y)
  lineRowOne.closePath()

  let lineRowTwo = lines.value[1] as Graphics
  app.value.stage.addChild(lineRowTwo)

  lineRowTwo.lineStyle(.5, 0xffffff, 1);
  lineRowTwo.moveTo(container.x, height * .66 + container.y)
  lineRowTwo.lineTo(container.x + width, height * .66 + container.y)
  lineRowTwo.closePath()

  let lineColOne = lines.value[2] as Graphics
  app.value.stage.addChild(lineColOne)

  lineColOne.lineStyle(.5, 0xffffff, 1);
  lineColOne.moveTo(container.x + width * .33, container.y)
  lineColOne.lineTo(container.x + width * .33, height + container.y)
  lineColOne.closePath()

  let lineColTwo = lines.value[3] as Graphics
  app.value.stage.addChild(lineColTwo)

  lineColTwo.lineStyle(.5, 0xffffff, 1);
  lineColTwo.moveTo(container.x + width * .66, container.y)
  lineColTwo.lineTo(container.x + width * .66, height + container.y)
  lineColTwo.closePath()
}

const closeUpload = () => {
  // this.$store.commit('setCutImage', { value: false })
}
const reset = () => {
  app.value.destroy(true)
  // app.value = new PIXI.Application()
  sprite.value = null
  drawArea.value = null
  // border.value = null
  graphList.value.forEach(t => {
    t.active = false
  })
}

watch(() => props.value, (value) => {
  if (value) {
    graphList.value[0].active = true
    init(value)
  } else {
    reset()
  }
})
onMounted(() => {
  if (props.value) {
    init(props.value as string)
  }
})

__expose({})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (cutImage.value.show)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", {
            class: "cut-image-head__cancel _common-btn1",
            onClick: handleCancel
          }, "取消"),
          _createElementVNode("div", {
            class: "cut-image-head__save",
            onClick: handleSave
          }, "保存")
        ]))
      : _createCommentVNode("", true),
    _createVNode(_unref(Dialog), {
      show: cutImage.value.show,
      "onUpdate:show": _cache[0] || (_cache[0] = ($event: any) => ((cutImage.value.show) = $event)),
      "show-confirm-button": false
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", {
            class: "cut-image-core",
            ref_key: "cutImageRef",
            ref: cutImageRef
          }, null, 512)
        ])
      ]),
      _: 1
    }, 8, ["show"]),
    (cutImage.value.show)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(graphList.value, (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(`cut-image-foot__item ${item.active ? 'active' : null}`),
                key: index,
                onClick: ($event: any) => (handleSwitch(item))
              }, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("i", {
                    class: _normalizeClass(`iconfont ${item.icon}`)
                  }, null, 2)
                ]),
                _createElementVNode("p", null, _toDisplayString(item.name), 1)
              ], 10, _hoisted_6))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})